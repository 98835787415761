import {
  AuthCard,
  AuthCardContent,
  Button,
  FormLayout,
  Modal,
} from "@ns/client-ui";
import { useTranslation } from "react-i18next";

import { useDialogContext } from "../../store/dialog-store.ts";
import CloseBtn from "../svg-components/close-btn.tsx";
import { PaymentPlatformEnum } from "../../enums/payment-platform.enum.ts";
import * as Sentry from "@sentry/react";
import { usePaymentStore } from "../../store/payment-store.ts";
import { CreateChargeRequest } from "@ns/api";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  placeBid: (data: CreateChargeRequest) => void;
}

const PopupBlockedDialogContext = ({ isOpen, onClose, placeBid }: Props) => {
  const { t } = useTranslation();

  const { bidData, setIsPaymentProcessingOpen } = usePaymentStore();

  const onClickHandler = () => {
    onClose();
    const bidRequest = {
      ...bidData,
      paymentPlatform: PaymentPlatformEnum.COIN_BASE,
    };
    try {
      placeBid(bidRequest);
      setIsPaymentProcessingOpen(true);
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setTag("place-bid-code", "basic");
        Sentry.captureException(error);
      });
    }
  };

  return (
    <Modal isOpen={isOpen}>
      <AuthCard>
        <AuthCardContent>
          <FormLayout
            subTitle={t("common.alert")}
            description={t("common.blockPopup")}
          >
            <CloseBtn
              className="absolute top-[30px] right-[16px]"
              onClick={onClose}
            />
            <div className="flex flex-col items-center justify-center gap-[1rem] w-full mt-[2rem]">
              <Button onClick={() => onClickHandler()} className={"w-full"}>
                {t("common.haveDisabled")}
              </Button>
              <Button
                onClick={onClose}
                variant={"secondary"}
                className={"w-full"}
              >
                {t("common.close")}
              </Button>
            </div>
          </FormLayout>
        </AuthCardContent>
      </AuthCard>
    </Modal>
  );
};

interface PopupBlockedProps {
  placeBid: (data: CreateChargeRequest) => void;
}

export const PopupBlockedDialog = ({ placeBid }: PopupBlockedProps) => {
  const { isPopupBlockedDialogOpen, toggleIsPopupBlockedDialogOpen } =
    useDialogContext();

  return (
    <PopupBlockedDialogContext
      isOpen={isPopupBlockedDialogOpen}
      onClose={toggleIsPopupBlockedDialogOpen}
      placeBid={placeBid}
    />
  );
};
